'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const styles = require('../../styles/package.css')

class GitHubStats extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {pulls: null, issues: null}
  }

  async componentDidMount() {
    const [pulls, issues] = await Promise.all([
      fetchApi(this.props.ghapi, 'pulls'),
      fetchApi(this.props.ghapi, 'issues'),
    ])
    if (pulls !== null && issues !== null) {
      this.setState({
        // issue count is actually issues + PRs
        issues: issues - pulls,
        pulls,
      })
    }
  }

  render() {
    const {pulls, issues} = this.state
    const {ghapi} = this.props
    const repoSlug = ghapi.match(/[^/]+\/[^/]+\/?$/)
    const repoUrl = `https://github.com/${repoSlug}`
    if (pulls === null || isNaN(issues)) return null
    return [
      <div className={styles.sidebarSection} key="issues">
        <h3 id="issues" className={styles.sidebarHeader}>
          Issues
        </h3>
        <p className={styles.sidebarText}>
          <a aria-describedby="issues" className={styles.sidebarLink} href={`${repoUrl}/issues`}>
            {issues}
          </a>
        </p>
      </div>,
      <div className={styles.sidebarSection} key="pulls">
        <h3 id="pulls" className={styles.sidebarHeader}>
          Pull Requests
        </h3>
        <p className={styles.sidebarText}>
          <a aria-describedby="pulls" className={styles.sidebarLink} href={`${repoUrl}/pulls`}>
            {pulls}
          </a>
        </p>
      </div>,
    ]
  }
}

GitHubStats.propTypes = {
  ghapi: PropTypes.string,
}

GitHubStats.defaultProps = {}

module.exports = GitHubStats

async function fetchApi(ghapi, resource = 'pulls') {
  try {
    const res = await window.fetch(`${ghapi}/${resource}?per_page=1`)
    const link = res.headers.get('Link')
    const count = parseLastPage(link)
    if (link != null) return count
    const body = await res.json()
    return body.length
  } catch (e) {
    return null
  }
}

function parseLastPage(linkHeader) {
  // the Link header looks something like:
  // <https://api.github.com/repositories/237159/pulls?per_page=1&page=2>; rel="next", \
  // <https://api.github.com/repositories/237159/pulls?per_page=1&page=44>; rel="last"
  if (!linkHeader) return null
  const links = linkHeader.split(',')
  const lastLink = links.find(link => link.match(/rel="last"/))
  return Number(lastLink.match(/[^_]page=(\d+)/)[1])
}
